
export const SYSTEMIC_LUPUS_ERYTHEMATOSUS = [
    /// Initial Symptom
    {
        field: "sle-InitialSymptom",
        label: "Initial Symptom",
        type: "multiselect",
        options: [
            "Cutaneous manifestation",
            "Hairfall",
            "Oral Ulcer",
            "Arthritis",
            "Arthralgia",
            "Constitutional",
            "Renal Involvement",
            "Serositis",
            "Myalgia",
            "Myositis",
            "Vasculitis",
            "Hematological Anemia",
            "Hematological Pancytopenia",
            "Hematological Leucopenia",
            "Hematological Thrumbocytopenia",
            "Serology",
        ]
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "sle-aNA-By-IFA-Intensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "sle-aNA-By-IFA-Titer",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "sle-aNA-By-IFA-Pattern",
        label: "ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Antibody Profile
    {
        field: "sle-antibodyProfile",
        label: "Antibody Profile",
        type: "multiselect",
        options: [
            "Anti-dsDNA : {+1/+2/+3}",
            "Anti-Sm : {+1/+2/+3}",
            "Anti-U1RNP : {+1/+2/+3}",
            "Anti-nucleosome : {+1/+2/+3}",
            "Anti-histones : {+1/+2/+3}",
            "Anti-Sjögren syndrome-A/Ro(anti-SSA/Ro) : {+1/+2/+3}",
            "Anti-RNP : {+1/+2/+3}",
            "Anti-Ku and Ki antibodies : {+1/+2/+3}",
            "Anti-ribosomal P protein (RPP) antibody : {+1/+2/+3}",
            "Anti- polymyositis-systemic scleroderma antibody (PM-Scl) : {+1/+2/+3}",
            "Anti-Sjögren's syndrome antigen-B (anti-SS-B/LA) : {+1/+2/+3}",
            "Anti-systemic sclerosis-70 (Scl-70) antibody : {+1/+2/+3}",
            "Anti-histidyl-tRNA synthetase antibody(Jo-1) : {+1/+2/+3}",

        ],
        extraOptions: [

            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-dsDNA : {+1/+2/+3}",
                    "Anti-Sm : {+1/+2/+3}",
                    "Anti-U1RNP : {+1/+2/+3}",
                    "Anti-nucleosome : {+1/+2/+3}",
                    "Anti-histones : {+1/+2/+3}",
                    "Anti-Sjögren syndrome-A/Ro(anti-SSA/Ro) : {+1/+2/+3}",
                    "Anti-RNP : {+1/+2/+3}",
                    "Anti-Ku and Ki antibodies : {+1/+2/+3}",
                    "Anti-ribosomal P protein (RPP) antibody : {+1/+2/+3}",
                    "Anti- polymyositis-systemic scleroderma antibody (PM-Scl) : {+1/+2/+3}",
                    "Anti-Sjögren's syndrome antigen-B (anti-SS-B/LA) : {+1/+2/+3}",
                    "Anti-systemic sclerosis-70 (Scl-70) antibody : {+1/+2/+3}",
                    "Anti-histidyl-tRNA synthetase antibody(Jo-1) : {+1/+2/+3}",
                ],
                placeholders: [

                    {
                        key: "+1/+2/+3",
                        type: "select",
                        label: "Strength",

                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ]
                    }
                ]
            },
        ]
    },
    /// Initially Treated with
    {
        field: "sle-initiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "Pulse methylprednisolone {value1} mg for {duration} Days",
            "Cyclophosphamide {value1} mg for {duration} months",
            "Rituximab {value}",
            "Methotrexate {value} mg per week",
            "Mycophenolate {value} mg twice a day",
            "Azathioprine {value} mg once a day",
            "Prednisolone {value} mg per day",
            "Hydroxychloroquine {value} mg",
            "Tadalafil 10mg",
            "Nifedipine 20mg SR",
            "Nintadinib 150mg BD",
        ],
        extraOptions: [

            {
                // When the user selects this trigger value…
                trigger: [
                    "Pulse methylprednisolone {value1} mg for {duration} Days",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "select",
                        label: "Dose",

                        options: [
                            "100",
                            "250",
                            "500",
                            "750",
                            "1000",
                        ]
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Days",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                        ]
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Cyclophosphamide {value1} mg for {duration} months",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "type",
                        label: "Dose",
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Months",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Rituximab {value}",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500 mg",
                            "1 g",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Methotrexate {value} mg per week",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "7.5",
                            "10",
                            "12.5",
                            "15",
                            "17.5",
                            "20",
                            "25",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Mycophenolate {value} mg twice a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500",
                            "1000",
                            "1500",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Azathioprine {value} mg once a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "25",
                            "50",
                            "75",
                            "100",
                            "125",
                            "150",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Prednisolone {value} mg per day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: Array.from({ length: 32 }, (_, i) => 2.5 * (i + 1)),
                    }
                ]
            },
            {
                trigger: [
                    "Hydroxychloroquine {value} mg",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "200",
                            "300",
                            "400",
                        ],
                    }
                ]
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "sle-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "sle-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "sle-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "sle-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "sle-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "sle-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Second Opinion",
            "Symptoms suggestive of increased Disease Activity due to non compliance to medicine and medical advices",
            "Inappropriate diagnosis",
            "Inadequate medication",
            "Other Illness",
            "Infection",
        ]
    }
]