import React, { useState, useEffect } from "react";
import { Button, Divider, message, Table } from "antd";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import "./historyPrintout.css";
import moment from "moment-timezone";
import { calculateAge } from "../../lib/constants/calculateAge";

const HistoryPrintout = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { appointmentId } = useParams();
    const [appointmentHistory, setAppointmentHistory] = useState("");
    const location = useLocation();

    const { sendTo } = location.state || {};
    const [DOB, setDOB] = useState("");
    const [age, setAge] = useState("");
    const [sendToWhat, setSendToWhat] = useState("");
    const [loading, setLoading] = useState("");
    const [details, setDetails] = useState("");
    let calculatedAge;

    const fetchData = () => {
        setLoading(true);
        const url = `${apiBaseUrl}/appointment/${appointmentId}`;

        axios
            .get(url)
            .then((response) => {
                if (response.data.success) {
                    const data = response.data.data;
                    setDetails(data);
                    setDOB(data.DOB);
                    setAppointmentHistory(data.appointmentHistory);
                    console.log(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching appointments:", error);
                message.error("Failed to get appointment details! Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getRelevantAppointmentHistory = (history) => {
        if (!history || history.length === 0) return null;

        // First check the latest entry (last index)
        let latestEntry = history[history.length - 1];

        // Check if latest entry meets criteria
        if (
            latestEntry.type === "Ongoing" ||
            latestEntry.type === "FollowUp" ||
            (latestEntry.type === "Referred" && latestEntry.doctor)
        ) {
            return latestEntry;
        }

        // If latest doesn't meet criteria, look backwards through history
        for (let i = history.length - 2; i >= 0; i--) {
            const entry = history[i];
            if (
                entry.type === "Ongoing" ||
                entry.type === "FollowUp" ||
                (entry.type === "Referred" && entry.doctor)
            ) {
                console.log("APPOINTMENT", entry);
                return entry;
            }
        }

        // If no valid entry found, return null
        return null;
    };

    const latestAppointment = getRelevantAppointmentHistory(appointmentHistory);


    const prescriptionColumns = [
        {
            title: 'Medicine',
            dataIndex: 'medicineName',
            key: 'medicineName',
            render: (text, record) => {
                // For follow-up doses
                if (record.isFollowUp) {
                    return <span className="text-gray-500 italic">Followed By:</span>;
                }

                // For first entry
                return (
                    <div>
                        <div className="font-medium">{text.split('[')[0].trim()}</div>
                        {record.comment && (
                            <div className="text-xs text-gray-500 mt-1">
                                {record.comment}
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            title: 'Tablet',
            dataIndex: 'tablet',
            key: 'tablet',
        },
        {
            title: 'Dose',
            dataIndex: 'dose',
            key: 'dose',
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (_, record) => (
                <div className="flex items-center gap-1">
                    <span>•</span>
                    {`${record.frequencyNumber}x/${record.frequencyPeriod}`}
                    {record.frequencyPeriod === 'week' && record.frequencyDays && (
                        <span className="text-xs text-gray-500 ml-2">
                            ({record.frequencyDays.join(', ')})
                        </span>
                    )}
                </div>
            )
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) =>
                `${record.durationNumber} ${record.durationPeriod}${record.durationNumber > 1 ? 's' : ''}`
        },
        {
            title: 'Meal',
            dataIndex: 'meal',
            key: 'meal',
            render: (text) => text?.charAt(0).toUpperCase() + text?.slice(1)
        }
    ];

    const prescriptionData = latestAppointment?.prescriptions?.flatMap((prescription, pIndex) =>
        prescription.medicine.flatMap((med, mIndex) =>
            med.details.map((detail, dIndex) => ({
                key: `${pIndex}-${mIndex}-${dIndex}`,
                medicineName: dIndex === 0 ? med.medicineName : null,
                comment: dIndex === 0 ? med.comment : null,
                tablet: detail.tablet,
                dose: detail.dose,
                isFollowUp: dIndex > 0,
                frequencyNumber: detail.frequency.number,
                frequencyPeriod: detail.frequency.period,
                frequencyDays: detail.frequency.selectedDays?.map(day =>
                    day.charAt(0).toUpperCase() + day.slice(1)
                ),
                durationNumber: detail.duration.number,
                durationPeriod: detail.duration.period,
                meal: detail.Meal
            }))
        )
    ) || [];

    const diagnosisColumns = [
        {
            title: 'Diagnosis',
            key: 'diagnosis',
            render: (_, record) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {record.diagnosis.replace(/\\n/g, '\n')}
                </div>
            )
        }
    ];

    const diagnosisData = latestAppointment?.diagnosis?.map((diag, index) => ({
        key: index,
        ...diag
    })) || [];

    const summaryColumns = [
        {
            title: 'Summary',
            key: 'summary',
            render: (_, record) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {record.summary.replace(/\\n/g, '\n')}
                </div>
            )
        }
    ];

    const summaryData = latestAppointment?.summary?.map((sum, index) => ({
        key: index,
        ...sum
    })) || [];

    // Advice Table
    const adviceColumns = [
        {
            title: 'Advice',
            key: 'advice',
            render: (_, record) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {record.advice.replace(/\\n/g, '\n')}
                </div>
            )
        }
    ];

    const adviceData = latestAppointment?.advice?.map((adv, index) => ({
        key: index,
        ...adv
    })) || [];
    useEffect(() => {
        calculatedAge = calculateAge(DOB);

        if (
            calculatedAge !== undefined &&
            calculatedAge !== "Invalid date of birth"
        ) {
            setAge(calculatedAge);
        } else {
            setAge("");
        }
    }, [DOB]);

    useEffect(() => {
        setSendToWhat(sendTo);
        fetchData();
    }, []);



    const handlePrint = () => {
        const printableContent =
            document.getElementById("printableContent").innerHTML;

        // Create a temporary div element
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = printableContent;
        // Replace the body content with the printable content
        document.body.innerHTML = tempDiv.innerHTML;

        window.print();
        if (sendToWhat === "Staff") {

            window.location.replace("/dashboard/viewappointment");
        } else if (sendToWhat === "DoctorReferred") {
            window.location.replace("/dashboard/viewReferredAppointment/toDoctor");
        }
        else if (sendToWhat === "DoctorEnded") {
            window.location.replace("/dashboard/viewEndedAppointmentbyDoctor");
        }
        else if (sendToWhat === "DoctorFollowUp") {
            window.location.replace("/dashboard/view/followup/appointments");
        }
        else {
            window.location.replace("/dashboard/viewOngoingAppointmentbyDoctor");
        }
    };

    const convertToNepaliTime = (utcTime) => {
        return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
    };

    return (
        <div>
            <div id="printableContent" className="print-content">
                <div className="container p-4 place-content-center">
                    <div className="mb-3">
                        <div className="text-center">
                            <span className="text-2xl">Tulsi MultiSpeciality Clinic</span>
                            <p>
                                <EnvironmentOutlined /> Avm chowk 05, Lalitpur 44600
                            </p>
                            <p>
                                <PhoneOutlined /> 01-5914294
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-between mb-8">
                        <div className="flex flex-col text-start">
                            {/* <p>Patient ID: {tableData?.appointment?.patientId?.userID}</p> */}
                            <p>Name: {details?.fullname}</p>
                            <p>
                                Age/ Sex: {age}
                                {"/"} {details?.gender}
                            </p>
                            <p>Address: {details?.address}</p>
                        </div>
                        <div className="flex flex-col text-end">
                            <p>Appointment No: {details?.appointmentNumber}</p>

                            <p>
                                Date:
                                {convertToNepaliTime(details?.date)}
                            </p>
                        </div>
                    </div>

                    <Divider />

                    <div className="space-y-6">

                        {summaryData.length > 0 && (
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Summary</h3>
                                <Table
                                    columns={summaryColumns}
                                    dataSource={summaryData}
                                    pagination={false}
                                    bordered
                                    size="small"
                                />
                            </div>
                        )}

                        {diagnosisData.length > 0 && (
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Diagnosis</h3>
                                <Table
                                    columns={diagnosisColumns}
                                    dataSource={diagnosisData}
                                    pagination={false}
                                    bordered
                                    size="small"
                                />
                            </div>
                        )}
                        {prescriptionData.length > 0 && (
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Prescriptions</h3>
                                <Table
                                    columns={prescriptionColumns}
                                    dataSource={prescriptionData}
                                    pagination={false}
                                    bordered
                                    size="small"
                                />
                            </div>
                        )}





                        {adviceData.length > 0 && (
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Advice</h3>
                                <Table
                                    columns={adviceColumns}
                                    dataSource={adviceData}
                                    pagination={false}
                                    bordered
                                    size="small"
                                />
                            </div>
                        )}
                    </div>

                    <div className="generated-by text-end me-5 mt-3">
                        {/* <p>User: {tableData?.generatedBy?.username}</p> */}
                    </div>
                </div>
            </div>
            {/* Print Button */}
            <Button
                type="primary"
                onClick={handlePrint}
                className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow font-semibold text-md rounded-md"
            >
                Print Invoice
            </Button>
        </div>
    );
};

export default HistoryPrintout;
