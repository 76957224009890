import { DEGENERATIVE_DISEASE } from "./diseases/degenerative_disease_config";
import { GOUTY_ARTHRITIS } from "./diseases/gouty_arthritis_config";
import { MIXED_CONNECTIVE_TISSUE } from "./diseases/mixed_connective_tissue_config";
import { MYOSITIS } from "./diseases/myositis_config";
import { OVERLAP_SYNDROMES } from "./diseases/overlap_syndromes_config";
import { PRIMARY_SJÖGREN_DISEASE } from "./diseases/primary_sjogren_disease_config";
import { RHEUMATOID_ARTHRITIS } from "./diseases/ra_config";
import { SYSTEMIC_LUPUS_ERYTHEMATOSUS } from "./diseases/sle_config";
import { SPONDYLOARTHRITIS } from "./diseases/spondyloarthritis_config";
import { SYSTEMIC_SCLEROSIS } from "./diseases/systemic_sclerosis_config";
import { UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE } from "./diseases/undifferentiated_connective_tissue_config";
import { VASCULITIS } from "./diseases/vasculitis_config";

/// Sabai ma Template vanera use garna milxa jasma hamle trigger ni match garna pardaina 
/// field ko options mani mathc huna pardaina 
/// vaneko field ko option ko value "digital gangrene" xa ani trigger mani "digital gangrene" xa 
/// vanesi template vanera provide garera "digital gangrene {extra}" use garera placeholder ma use garna milxa
/// tala jasari

// {
//     // For example, an extra option for digital gangrene:
//     trigger: "digital gangrene",
//     placeholders: [
//         {
//             key: "extra",
//             type: "text",
//             label: "Digital gangrene extra info"
//         }
//     ],
//     // In this case, a template is provided.
//     template: "digital gangrene ({extra})"
// }


export const diseaseFieldConfig = {
    RHEUMATOID_ARTHRITIS,

    SYSTEMIC_LUPUS_ERYTHEMATOSUS,

    SPONDYLOARTHRITIS,

    SYSTEMIC_SCLEROSIS,

    MYOSITIS,

    MIXED_CONNECTIVE_TISSUE,

    GOUTY_ARTHRITIS,

    VASCULITIS,

    DEGENERATIVE_DISEASE,

    PRIMARY_SJÖGREN_DISEASE,

    OVERLAP_SYNDROMES,

    UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE,
};
