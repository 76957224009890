export const MIXED_CONNECTIVE_TISSUE = [
    /// Initial Symptom
    {
        field: "mct-initialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "Arthritis",
            "Arthralgia",
            "Raynaud’s phenomenon {value} phase",
            "Muscle weakness",
            "Skin thickening",
            "Shortness of breath",
            "Chest pain",
            "Photosensitivity",
            "Constitutional symptoms",
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: "Raynaud’s phenomenon {value} phase",
                placeholders: [
                    {
                        key: "value",
                        type: "multiselect", // or "text" for free input
                        label: "Raynaud's phenomenon phase",
                        options: ["Single", "Double", "Triple"]
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Pertinent Antibodies Anti-U1 RNP Antibody
    {
        field: "mct-PertinentAntibodiesAnti-U1-RNP-Antibody",
        label: "Pertinent Antibodies Anti-U1 RNP Antibody",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Intensity Field
    {
        field: "mct-PertinentAntibodies-aNA-By-IFA-Intensity",
        label: "Pertinent Antibodies ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "mct-PertinentAntibodies-aNA-By-IFA-Titer",
        label: "Pertinent Antibodies ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "mct-PertinentAntibodies-aNA-By-IFA-Pattern",
        label: "Pertinent Antibodies ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Pertinent Antibodies Rheumatoid Factor (RF)
    {
        field: "mct-PertinentAntibodies-RF",
        label: "Pertinent Antibodies (Rheumatoid Factor (RF))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
        ],

    },
    /// Pertinent Antibodies Anti-Cyclic Citrullinated Peptide (Anti-CCP)
    {
        field: "mct-PertinentAntibodies-Anti-CCP",
        label: "Pertinent Antibodies (Anti-Cyclic Citrullinated Peptide (Anti-CCP))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
        ],
    },
    /// Screening for PAH
    {
        field: "mct-ScreeningForPAH",
        label: "ScreeningForPAH",
        type: "select",
        options: [
            "Done",
            "Not Done",
            "Refused",
            "PAP {value} mmof Hg",
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: "PAP {value} mmof Hg",
                placeholders: [
                    {
                        key: "value",
                        type: "type", // or "text" for free input
                        label: "PAP mmof Hg",
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "mct-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "mct-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "mct-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "mct-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "mct-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "mct-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms not controlled on medication",
            "Developed new symptoms suggestive of shortness of breath",
            "Developed new symptoms suggestive of raynauds",
            "Developed new symptoms suggestive of contitutional",
            "Developed new symptoms suggestive of arthritis",
            "Symptoms suggestive of Infection",
        ]
    }
];