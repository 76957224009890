export const PRIMARY_SJÖGREN_DISEASE = [
    /// Initial Symptom
    {
        field: "psd-initialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "Exocrinopathy",
            "Arthritis",
            "Arthalgia",
            "Myalgia",
            "Constitutional problem",
            "Cutaneous involvement",
            "Neurological involvement",
            "Hematological involvement",
            "Renal involvement",
        ],
    },
    /// Total Duration of Illness
    {
        field: "psd-totalDurationOfIllness",
        label: "Total Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Initially treated with Field
    {
        field: "psd-initiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "Pulse methylprednisolone {value1} mg for {duration} Days",
            "Cyclophosphamide {value1} mg for {duration} months",
            "Rituximab {value}",
            "Methotrexate {value} mg per week",
            "Mycophenolate {value} mg twice a day",
            "Azathioprine {value} mg once a day",
            "Prednisolone {value} mg per day",
            "Hydroxychloroquine {value} mg",
            "Tadalafil 10mg",
            "Nifedipine 20mg SR",
            "Nintadinib 150mg BD",
        ],
        extraOptions: [

            {
                // When the user selects this trigger value…
                trigger: [
                    "Pulse methylprednisolone {value1} mg for {duration} Days",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "select",
                        label: "Dose",

                        options: [
                            "100",
                            "250",
                            "500",
                            "750",
                            "1000",
                        ]
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Days",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                        ]
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Cyclophosphamide {value1} mg for {duration} months",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "type",
                        label: "Dose",
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Months",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Rituximab {value}",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500 mg",
                            "1 g",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Methotrexate {value} mg per week",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "7.5",
                            "10",
                            "12.5",
                            "15",
                            "17.5",
                            "20",
                            "25",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Mycophenolate {value} mg twice a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500",
                            "1000",
                            "1500",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Azathioprine {value} mg once a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "25",
                            "50",
                            "75",
                            "100",
                            "125",
                            "150",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Prednisolone {value} mg per day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: Array.from({ length: 32 }, (_, i) => 2.5 * (i + 1)),
                    }
                ]
            },
            {
                trigger: [
                    "Hydroxychloroquine {value} mg",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "200",
                            "300",
                            "400",
                        ],
                    }
                ]
            },
        ]
    },
    /// Progression
    {
        field: "psd-progression",
        label: "Progression",
        type: "select",
        options: [
            "Symptoms improved",
            "Symptoms not improved",
            "Symptoms remained static",
            "Symptoms progressed on treatment",
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "psd-aNA-By-IFA-Intensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "psd-aNA-By-IFA-Titer",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "psd-aNA-By-IFA-Pattern",
        label: "ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Comorbidities Field
    {
        field: "psd-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "psd-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "psd-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "psd-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "psd-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "psd-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms suggestive of persistent disease activity",
            "Symptoms suggestive of Infection",
            "Symptoms suggestive of side effect of drugs",
            "Symptoms suggestive of New symptoms",
        ],
    },
]