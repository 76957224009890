export const GOUTY_ARTHRITIS = [
    /// Total Duration of Illness
    {
        field: "ga-durationOfIllness",
        label: "Duration of Illness",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Total attacks in years
    {
        field: "ga-totalAttacksInYears",
        label: "Total attacks in years",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: ["Months", "Years"]
            }
        ]
    },
    /// Total attacks in months
    {
        field: "ga-totalAttacksInMonths",
        label: "Total attacks in months",
        type: "composite",
        fields: [
            {
                field: "durationValue",
                label: "Duration",
                type: "select",
                // Options: numbers 1 to 20
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            },
            {
                field: "durationUnit",
                label: "Unit",
                type: "select",
                options: Array.from({ length: 20 }, (_, i) => i + 1)
            }
        ]
    },
    ///! Initial Attack
    {
        field: "ga-initialAttack",
        label: "Initial Attack",
        type: "multiselect",
        options: [
            "Vinaju le Data Provide garna baki xa",
        ]
    },
    ///! Current Attack
    {
        field: "ga-currentAttack",
        label: "Current Attack",
        type: "multiselect",
        options: [
            "Vinaju le Data Provide garna baki xa",
        ]
    },
    /// Family History
    {
        field: "ga-familyHistory",
        label: "Family History",
        type: "multiselect",
        options: [
            "Positive in Father",
            "Positive in brother",
            "Positive in GrandFather",
            "Negative",
        ]
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "ga-aNA-By-IFA-Intensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "ga-aNA-By-IFA-Titer",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "ga-aNA-By-IFA-Pattern",
        label: "ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Current Alcohol Consumption
    {
        field: "ga-currentAlcoholConsumption",
        label: "Current Alcohol Consumption",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Screening for
    {
        field: "ga-screeningFor",
        label: "Screening for",
        type: "multiselect",
        options: [
            "Diabetes {Done/Not Done/Refused}",
            "Dyslipidemia {Done/Not Done/Refused}",
            "Cardiac issues {Done/Not Done/Refused}",
            "Fatty liver {Done/Not Done/Refused}",
            "Nephrolithiasis {Done/Not Done/Refused}",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "Diabetes {Done/Not Done/Refused}",
                    "Dyslipidemia {Done/Not Done/Refused}",
                    "Cardiac issues {Done/Not Done/Refused}",
                    "Fatty liver {Done/Not Done/Refused}",
                    "Nephrolithiasis {Done/Not Done/Refused}",
                ],
                placeholders: [

                    {
                        key: "Done/Not Done/Refused",
                        type: "select",
                        label: "Condition",

                        options: [
                            "Done",
                            "Not Done",
                            "Refused",
                        ]
                    }
                ]
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "ga-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "ga-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "ga-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "ga-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "ga-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "ga-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms suggestive of increased disease activity",
            "Symptoms suggestive of side effects of drugs",
        ]
    }
]