export const SPONDYLOARTHRITIS = [
    /// Initial Symptoms Field
    {
        field: "sda-InitialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "Inflammatory low back pain",
            "Dactylitis",
            "Psoriasis",
            "Inflammatory bowel disease",
            "Reactive arthritis",
            "Uveitis"
        ]
    },
    /// Initial Investigation
    {
        field: "sda-InitialInvestigation",
        label: "Initial Investigation",
        type: "multiselect",
        options: [
            "CRP: High",
            "CRP: Low",
            "HLAB27: +",
            "HLAB27: -"
        ]
    },
    /// X ray SI joint
    {
        field: "sda-xRaySiJoint",
        label: "X ray SI joint",
        type: "select",
        options: [
            "Sacroillitis +",
            "Sacroillitis -"
        ]
    },
    /// MRI
    {
        field: "sda-mri",
        label: "MRI",
        type: "select",
        options: [
            "Sacroillitis +",
            "Sacroillitis -"
        ]
    },
    /// Response to NSAIDs
    {
        field: "sda-responseToNSAIDs",
        label: "Response to NSAIDs",
        type: "checkbox",
        options: [
            "Yes",
            "No"
        ]
    },
    /// FamilyHistory
    {
        field: "sda-familyHistory",
        label: "FamilyHistory",
        type: "checkbox",
        options: [
            "Positive",
            "Negative"
        ]
    },
    /// Initially treated with
    {
        field: "sda-initiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "Sulfasalazine",
            "Methotrexate",
            "Tofacitinib",
            "Adalimumab",
        ]
    },
    /// Response To Initial Treatment
    {
        field: "sda-responseToInitialTreatment",
        label: "Response To Initial Treatment",
        type: "select",
        options: [
            "Good Response",
            "Adverse Reaction",
            "Not Tolorated",
            "No Response",
        ]
    },
    /// Subsequent extra articular involvement
    {
        field: "sda-subsequentExtraArticularInvolvement",
        label: "Subsequent extra articular involvement",
        type: "multiselect",
        options: [
            "Psoriasis",
            "Inflammatory bowel disease",
            "Uveitis",
        ]
    },
    /// Screening for
    {
        field: "sda-screeningFor",
        label: "Screening for",
        type: "multiselect",
        options: [
            "Diabetes {Done/Not Done/Refused}",
            "Dyslipidemia {Done/Not Done/Refused}",
            "Cardiac comorbidities {Done/Not Done/Refused}",
        ],
        extraOptions: [

            {
                // When the user selects this trigger Done/Not Done/Refused…
                trigger: [
                    "Diabetes {Done/Not Done/Refused}",
                    "Dyslipidemia {Done/Not Done/Refused}",
                    "Cardiac comorbidities {Done/Not Done/Refused}",
                ],
                placeholders: [

                    {
                        key: "Done/Not Done/Refused",
                        type: "select",
                        label: "Condition",

                        options: [
                            "Done",
                            "Not Done",
                            "Refused",
                        ]
                    }
                ]
            },
        ]

    },
    /// Comorbidities
    {
        field: "sda-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "sda-aNA-By-IFA-Intensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "sda-aNA-By-IFA-Titer",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "sda-aNA-By-IFA-Pattern",
        label: "ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Conception Plan Field
    {
        field: "sda-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "sda-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "sda-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "sda-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "sda-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Second Opinion",
            "Symptoms suggestive of increased Disease Activity due to non compliance to medicine and medical advices",
            "Inappropriate diagnosis",
            "Inadequate medication",
            "Other Illness",
            "Infection",
        ],
    },
]