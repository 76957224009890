export const UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE = [
    /// Initial Symptom
    {
        field: "uctd-initialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "Arthritis",
            "Arthralgia",
            "Proximal muscle weakness",
            "Photosensitivity",
            "Oral ulcer",
            "Grittiness of eyes",
            "Raynaud's",
            "Shortness of breath",
            "Pleurisy",
            "Alopacia",
            "Weight loss",
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "uctd-PertinentAntibodiesInUCTD-aNA-By-IFA-Intensity",
        label: "Pertinent Antibodies In UCTD ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "uctd-PertinentAntibodiesInUCTD-aNA-By-IFA-Titer",
        label: "Pertinent Antibodies In UCTD ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "uctd-PertinentAntibodiesInUCTD-aNA-By-IFA-Pattern",
        label: "Pertinent Antibodies In UCTD ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Pertinent Antibodies in UCTD (Extractable Nuclear Antigens (ENAs))
    {
        field: "uctd-PertinentAntibodiesInUCTD-ENAs",
        label: "Pertinent Antibodies in UCTD (Extractable Nuclear Antigens (ENAs))",
        type: "multiselect",
        options: [
            "Anti-Ro/SSA and Anti-La/SSB {+1/+2/+3}",
            "Anti-U1 RNP {+1/+2/+3}",
            "Anti-Sm {+1/+2/+3}",
            "Anti-Jo-1 {+1/+2/+3}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-Ro/SSA and Anti-La/SSB {+1/+2/+3}",
                    "Anti-U1 RNP {+1/+2/+3}",
                    "Anti-Sm {+1/+2/+3}",
                    "Anti-Jo-1 {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select", // or "text" for free input
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Pertinent Antibodies in UCTD (Antiphospholipid Antibodies )
    {
        field: "uctd-PertinentAntibodiesInUCTD-AntiphospholipidAntibodies",
        label: "Pertinent Antibodies in UCTD (Antiphospholipid Antibodies)",
        type: "multiselect",
        options: [
            "Anti-cardiolipin (Low-titer)",
            "Anti-cardiolipin (High-titer)",
            "Beta-2 glycoprotein I (Low-titer)",
            "Beta-2 glycoprotein I (High-titer)",
            "Lupus anticoagulant (Low-titer)",
            "Lupus anticoagulant (High-titer)",
        ],

    },
    /// Pertinent Antibodies in UCTD Rheumatoid Factor (RF)
    {
        field: "uctd-PertinentAntibodiesInUCTD-RF",
        label: "Pertinent Antibodies in UCTD (Rheumatoid Factor (RF))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
        ],

    },
    /// Pertinent Antibodies In UCTD Anti-Cyclic Citrullinated Peptide (Anti-CCP)
    {
        field: "uctd-PertinentAntibodiesInUCTD-Anti-CCP",
        label: "Pertinent Antibodies In UCTD (Anti-Cyclic Citrullinated Peptide (Anti-CCP))",
        type: "select",
        options: [
            "Low-Titer",
            "High-Titer",
        ],
    },
    /// Pertinent Antibodies in UCTD (Myositis-Specific and Myositis-Associated Antibodies)
    {
        field: "uctd-PertinentAntibodiesInUCTD-MS&MAA",
        label: "Pertinent Antibodies in UCTD (Myositis-Specific and Myositis-Associated Antibodies)",
        type: "multiselect",
        options: [
            "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
            "Anti-Mi-2 {+1/+2/+3}",
            "Anti-TIF1-y (Anti-p155/140) {+1/+2/+3}",
            "Anti-NXP-2 {+1/+2/+3}",
            "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
            "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
            "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
                    "Anti-Mi-2 {+1/+2/+3}",
                    "Anti-TIF1-y (Anti-p155/140) {+1/+2/+3}",
                    "Anti-NXP-2 {+1/+2/+3}",
                    "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
                    "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
                    "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select", // or "text" for free input
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
                // If no template is provided, the trigger string is used.
            },
        ]
    },
    /// Comorbidities Field
    {
        field: "uctd-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "uctd-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "uctd-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "uctd-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "uctd-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "uctd-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms suggestive of adverse effect of drugs",
            "Symptoms suggestive of increased disease activity",
            "Symptoms suggestive of infection of respiratory tract",
            "Symptoms suggestive of soft tissue involvement",
            "Symptoms suggestive of gastrointestinal tract involvement",
        ]
    }
]