// rheumatoid_arthritis_config.js
export const RHEUMATOID_ARTHRITIS = [
    //! Presentation
    {
        field: "raPresentation",
        label: "Presentation",
        type: "select",
        options: [
            "Option 1: ...",
            "Option 2: ...",
            "Option 3: ..."
        ]
    },
    /// Joint Examination
    {
        field: "raJointExam",
        label: "Joint Examination",
        type: "multiselect",
        options: [
            "{Right/Left} - {1st/2nd/3rd/4th} {MCP/PIP} were {Tender/Swollen}",
            "With deformities",
            "Without deformities"
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: [
                    "{Right/Left} - {1st/2nd/3rd/4th} {MCP/PIP} were {Tender/Swollen}",
                ],
                placeholders: [
                    {
                        key: "Right/Left",
                        type: "select",
                        label: "Alignment",
                        options: [
                            "Right",
                            "Left",
                        ],
                    },
                    {
                        key: "1st/2nd/3rd/4th",
                        type: "multiselect",
                        label: "Position",
                        options: [
                            "1st",
                            "2nd",
                            "3rd",
                            "4th",
                        ],
                    },
                    {
                        key: "MCP/PIP",
                        type: "multiselect",
                        label: "MCP/PIP",
                        options: [
                            "MCP",
                            "PIP",
                        ],
                    },
                    {
                        key: "Tender/Swollen",
                        type: "select",
                        label: "Tender/Swollen",
                        options: [
                            "Tender",
                            "Swollen",
                            "Tender & Swollen",
                        ],
                    },
                ]
            },
        ]
    },
    //! Lab Investigation
    {
        field: "raLabInvestigations",
        label: "Lab Investigations",
        type: "select",
        options: [
            "Rheumatoid factor: Positive",
            "AntiCCP: Positive",
            "ESR: Raised",
            "CRP: Raised"
        ]
    },
    //! Current Issues
    {
        field: "currentIssues",
        label: "Current Issues",
        type: "select",
        options: [
            "Second Opinion",
            "Increased Disease Activity",
            "Non compliance to medication",
            "Inadequate medication",
            "Other Illness/Infection"
        ]
    },

    /// Comorbidities Field
    {
        field: "ra-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "ra-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "ra-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "ra-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "ra-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
]