import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Input, Row, Col, message, Button, Modal, Select, Tag, Typography, Space, Checkbox, Radio, Tabs } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { diseaseFieldConfig } from "./config";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Title } = Typography;
const { TextArea } = Input;

const ViewSummary = () => {

    // State to track the selected disease and the form data.
    const [selectedDisease, setSelectedDisease] = useState("");
    const [formData, setFormData] = useState({});

    const [forms, setForms] = useState([
        { id: 1, selectedDisease: "", formData: {} },
    ]);
    const [activeTabKey, setActiveTabKey] = useState("1");

    /* 
     dynamicExtra is used to store the current extra input request.
     When non-null, it is an object:
     {
       field, // e.g. "initialSymptoms"
       extraOption, // the extraOption object from config (one of the objects in extraOptions array)
       values: { [placeholderKey]: userInput, ... } // collected values from modal inputs
     }
   */
    const [dynamicExtra, setDynamicExtra] = useState(null);



    // Get the config for the selected disease (if any).
    const getConfigDisease = (form) =>
        form.selectedDisease ? diseaseFieldConfig[form.selectedDisease] : [];
    const configDisease = selectedDisease ? diseaseFieldConfig[selectedDisease] : [];


    const handleDiseaseChange = (formId, value) => {
        setForms((prevForms) =>
            prevForms.map((f) =>
                f.id === formId ? { ...f, selectedDisease: value, formData: {} } : f
            )
        );
    };

    const handleChange = (formId, field, value) => {
        setForms((prevForms) =>
            prevForms.map((f) =>
                f.id === formId
                    ? { ...f, formData: { ...f.formData, [field]: value } }
                    : f
            )
        );
    };

    const handleCompositeChange = (formId, compositeField, subField, value) => {
        setForms((prevForms) =>
            prevForms.map((f) => {
                if (f.id === formId) {
                    return {
                        ...f,
                        formData: {
                            ...f.formData,
                            [compositeField]: {
                                ...(f.formData[compositeField] || {}),
                                [subField]: value,
                            },
                        },
                    };
                }
                return f;
            })
        );
    };

    const checkAndTriggerExtraOption = (formId, field, value) => {
        const form = forms.find((f) => f.id === formId);
        const configDisease = getConfigDisease(form);
        const fieldConfig = configDisease.find((fc) => fc.field === field);
        if (fieldConfig && fieldConfig.extraOptions) {
            const extra = fieldConfig.extraOptions.find((opt) => {
                return Array.isArray(opt.trigger)
                    ? opt.trigger.includes(value)
                    : opt.trigger === value;
            });
            if (extra) {
                // Initialize dynamicExtra with empty values for each placeholder.
                const initialValues = {};
                if (Array.isArray(extra.placeholders)) {
                    extra.placeholders.forEach((p) => {
                        initialValues[p.key] = p.type === "multiselect" ? [] : "";
                    });
                }
                setDynamicExtra({
                    formId,
                    field,
                    extraOption: extra,
                    values: initialValues,
                    selectedTrigger: value, // Store the actual trigger value.
                });
            }
        }
    };


    const handleMultiSelectChange = (formId, field, valueArray) => {
        const form = forms.find((f) => f.id === formId);
        const configDisease = getConfigDisease(form);
        const fieldConfig = configDisease.find((fc) => fc.field === field);
        let triggers = [];
        if (fieldConfig && fieldConfig.extraOptions) {
            triggers = fieldConfig.extraOptions.flatMap((opt) =>
                Array.isArray(opt.trigger) ? opt.trigger : [opt.trigger]
            );
        }
        const cleaned = valueArray.filter((val) => !triggers.includes(val));
        setForms((prevForms) =>
            prevForms.map((f) =>
                f.id === formId
                    ? { ...f, formData: { ...f.formData, [field]: cleaned } }
                    : f
            )
        );
    };

    const handleMultiSelectSelect = (formId, field, value) => {
        checkAndTriggerExtraOption(formId, field, value);
    };



    // ---------- Modal Handlers for Extra Options ----------
    const handleDynamicExtraOk = () => {
        if (dynamicExtra) {
            const { extraOption, values, field, formId, selectedTrigger } = dynamicExtra;
            // Use the template if provided; otherwise, use the selected trigger value.
            let finalString = extraOption.template || selectedTrigger;
            // Replace placeholders with user values.
            if (extraOption.placeholders && Array.isArray(extraOption.placeholders)) {
                extraOption.placeholders.forEach((placeholder) => {
                    let replacement = values[placeholder.key];
                    if (Array.isArray(replacement)) {
                        replacement = replacement.join(", ");
                    }
                    finalString = finalString.replace(`{${placeholder.key}}`, replacement);
                });
            }

            // Update the forms state by checking the field configuration.
            setForms((prevForms) =>
                prevForms.map((f) => {
                    if (f.id === formId) {
                        // Get the configuration for this form’s selected disease.
                        const config = getConfigDisease(f);
                        const fieldConfig = config.find((fc) => fc.field === field);
                        if (fieldConfig && fieldConfig.type === "select") {
                            // For single select, simply update the value as a string.
                            return {
                                ...f,
                                formData: { ...f.formData, [field]: finalString },
                            };
                        } else {
                            // For multiselect (or other types that expect an array):
                            // Get the current field value and ensure it is an array.
                            const current = f.formData[field];
                            const currentArray = Array.isArray(current)
                                ? current
                                : current
                                    ? [current]
                                    : [];
                            // Remove the raw trigger value (the template with placeholders) from the array.
                            const cleanedArray = currentArray.filter(
                                (val) => val !== selectedTrigger
                            );
                            // Add the finalized string if not already present.
                            if (!cleanedArray.includes(finalString)) {
                                cleanedArray.push(finalString);
                            }
                            return {
                                ...f,
                                formData: { ...f.formData, [field]: cleanedArray },
                            };
                        }
                    }
                    return f;
                })
            );
        }
        setDynamicExtra(null);
    };





    const handleDynamicExtraCancel = () => {
        setDynamicExtra(null);
    };


    // ---------- TAB MANAGEMENT ----------
    const handleAddForm = () => {
        const newId = forms.length ? Math.max(...forms.map((f) => f.id)) + 1 : 1;
        setForms([...forms, { id: newId, selectedDisease: "", formData: {} }]);
        setActiveTabKey(String(newId));
    };

    const handleRemoveForm = (formId) => {
        // Remove the form with the specified id.
        const newForms = forms.filter((f) => f.id !== formId);
        setForms(newForms);
        // If the removed tab was active, switch to another.
        if (activeTabKey === String(formId)) {
            setActiveTabKey(newForms.length ? String(newForms[0].id) : "");
        }
    };

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    // ---------- Global Submit Handler ----------
    const handleSubmitAll = () => {
        // Perform any submission logic here. For example, log all form data:
        console.log("Submitting all forms:", forms);
        // You can send 'forms' to your API or process it as needed.
    };

    const handleClear = (formId, field, type) => {
        setForms((prevForms) =>
            prevForms.map((f) => {
                if (f.id === formId) {
                    let clearedValue;
                    // For composite fields, we clear by setting an empty object.
                    if (type === "composite") {
                        clearedValue = {};
                    } else if (type === "multiselect") {
                        clearedValue = [];
                    } else {
                        clearedValue = "";
                    }
                    return {
                        ...f,
                        formData: {
                            ...f.formData,
                            [field]: clearedValue,
                        },
                    };
                }
                return f;
            })
        );
    };


    // ---------- RENDERING ONE FORM ----------
    const renderForm = (form) => {
        const configDisease = getConfigDisease(form);
        return (
            <div style={{ maxWidth: "9  00px", margin: "auto", padding: "20px" }}>
                <Title level={2}>Diagnosis Form (Form {form.id})</Title>
                <Form
                    layout="vertical"
                    onFinish={() => console.log("Submitting form", form.id, form.formData)}
                >
                    {/* Disease Selector */}
                    <Form.Item label="Select Disease">
                        <Select
                            placeholder="Select Disease"
                            value={form.selectedDisease || undefined}
                            onChange={(value) => handleDiseaseChange(form.id, value)}
                        >
                            <Select.Option value="">--Select Disease--</Select.Option>
                            {Object.keys(diseaseFieldConfig).map((disease) => (
                                <Select.Option key={disease} value={disease}>
                                    {disease.replace(/_/g, " ")}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* Render dynamic fields only if a disease is selected */}
                    {form.selectedDisease && (
                        <>
                            <Title level={4}>
                                {form.selectedDisease.replace(/_/g, " ")}
                            </Title>
                            {configDisease.map((fieldConfig) => {
                                // Composite fields
                                if (fieldConfig.type === "composite") {
                                    return (
                                        <div key={fieldConfig.field}>
                                            <Form.Item label={fieldConfig.label}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {fieldConfig.fields.map((subField) => (
                                                        <div key={subField.field} style={{ flex: 1, marginRight: "16px" }}>
                                                            <Form.Item label={subField.label} style={{ marginBottom: 0 }}>
                                                                <Select
                                                                    placeholder={`Select ${subField.label}`}
                                                                    value={
                                                                        (form.formData[fieldConfig.field] &&
                                                                            form.formData[fieldConfig.field][subField.field]) ||
                                                                        undefined
                                                                    }
                                                                    onChange={(value) =>
                                                                        handleCompositeChange(
                                                                            form.id,
                                                                            fieldConfig.field,
                                                                            subField.field,
                                                                            value
                                                                        )
                                                                    }
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    {subField.options.map((opt) => (
                                                                        <Select.Option key={opt} value={opt}>
                                                                            {opt}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    {/* Inline Clear button for the entire composite field */}
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            handleClear(form.id, fieldConfig.field, fieldConfig.type)
                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    );
                                }
                                // Multiselect fields
                                else if (fieldConfig.type === "multiselect") {
                                    return (
                                        <div key={fieldConfig.field}>
                                            <Form.Item label={fieldConfig.label}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: 1 }}>
                                                        <Select
                                                            mode="multiple"
                                                            placeholder={`Select ${fieldConfig.label}`}
                                                            value={form.formData[fieldConfig.field] || []}
                                                            onChange={(valueArray) =>
                                                                handleMultiSelectChange(form.id, fieldConfig.field, valueArray)
                                                            }
                                                            onSelect={(value) =>
                                                                handleMultiSelectSelect(form.id, fieldConfig.field, value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        >
                                                            {fieldConfig.options.map((option) => (
                                                                <Select.Option key={option} value={option}>
                                                                    {option}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            handleClear(form.id, fieldConfig.field, fieldConfig.type)
                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    );
                                }
                                // Checkbox fields (rendered as Radio.Group for single selection)
                                else if (fieldConfig.type === "checkbox") {
                                    return (
                                        <div key={fieldConfig.field}>
                                            <Form.Item label={fieldConfig.label}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: 1 }}>
                                                        <Radio.Group
                                                            options={fieldConfig.options || ["Yes", "No"]}
                                                            value={form.formData[fieldConfig.field] || ""}
                                                            onChange={(e) =>
                                                                handleChange(form.id, fieldConfig.field, e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            handleClear(form.id, fieldConfig.field, fieldConfig.type)
                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    );
                                } else if (fieldConfig.type === "type") {
                                    return (
                                        <Form.Item label={fieldConfig.label} key={fieldConfig.field}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Input
                                                        placeholder={`Enter ${fieldConfig.label}`}
                                                        value={form.formData[fieldConfig.field] || ""}
                                                        onChange={(e) =>
                                                            handleChange(form.id, fieldConfig.field, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <Button
                                                    type="link"
                                                    onClick={() =>
                                                        handleClear(form.id, fieldConfig.field, fieldConfig.type)
                                                    }
                                                >
                                                    Clear
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    );
                                }
                                // Simple select (or any other type)
                                else {
                                    return (
                                        <Form.Item label={fieldConfig.label} key={fieldConfig.field}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Select
                                                        placeholder={`Select ${fieldConfig.label}`}
                                                        value={form.formData[fieldConfig.field] || undefined}
                                                        onChange={(value) =>
                                                            handleChange(form.id, fieldConfig.field, value)
                                                        }
                                                        onSelect={(value) =>
                                                            checkAndTriggerExtraOption(form.id, fieldConfig.field, value)
                                                        }
                                                        style={{ width: "100%" }}
                                                    >
                                                        {fieldConfig.options.map((opt) => (
                                                            <Select.Option key={opt} value={opt}>
                                                                {opt}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <Button
                                                    type="link"
                                                    onClick={() =>
                                                        handleClear(form.id, fieldConfig.field, fieldConfig.type)
                                                    }
                                                >
                                                    Clear
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    );
                                }
                            })}

                        </>
                    )}
                </Form>
                {/* Existing debug for this form's data */}
                <pre
                    style={{
                        background: "#f0f0f0",
                        padding: "10px",
                        marginTop: "20px",
                    }}
                >
                    {JSON.stringify(form.formData, null, 2)}
                </pre>
            </div>
        );
    };





    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { appointmentId } = useParams();
    // const navigate = useNavigate();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const location = useLocation();
    const { full_name, problem, appointmentNumber, appointmentHistory } = location.state || {};
    const [fullNameData, setFullNameData] = useState("");
    const [problemData, setProblemData] = useState("");
    const [appointmentNumberData, setAppointmentNumberData] = useState("");

    const [hasEditableSummary, setHasEditableSummary] = useState(false);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [currentSummaryId, setCurrentSummaryId] = useState(null);


    // for add summary modal
    const [selectedDiseases, setSelectedDiseases] = useState([]);

    const config = {
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("doctorticket") ||
                    localStorage.getItem("staffticket")),
        },
    };


    const handleDiseaseSelectUpdate = (category) => {
        // if (!selectedDiseases.includes(category)) {
        //   const newDiseases = [...selectedDiseases, category];
        //   setSelectedDiseases(newDiseases);

        //   // Update textarea with selected disease text
        //   const currentText = updateForm.getFieldValue('summary') || '';
        //   const diseaseText = diseaseOptions[category];
        //   const newText = currentText ? `${currentText}\n\n${category}:\n${diseaseText}` : `${category}:\n${diseaseText}`;
        //   updateForm.setFieldsValue({ summary: newText });
        // }
    };


    const handleDiseaseRemoveUpdate = (categoryToRemove) => {
        const newDiseases = selectedDiseases.filter(category => category !== categoryToRemove);
        setSelectedDiseases(newDiseases);

        // Update textarea by removing the category and its text
        const currentText = updateForm.getFieldValue('summary') || '';
        const textParts = currentText.split('\n\n');
        const newText = textParts
            .filter(part => !part.startsWith(`${categoryToRemove}:`))
            .join('\n\n');
        updateForm.setFieldsValue({ summary: newText });
    };
    // const doctorConfig = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    //   },
    // };

    // const staffConfig = {
    //   headers: {
    //     Authorization: "Bearer " + localStorage.getItem("staffticket"),
    //   },
    // };

    useEffect(() => {
        setFullNameData(full_name);
        setProblemData(problem);
        setAppointmentNumberData(appointmentNumber);
        fetchData();

    }, []);

    useEffect(() => {
        const checkEditableSummary = () => {
            if (!details || details.length === 0) return false;

            return details.some(item => {
                if (!item.appointmentId?.appointmentHistory) return false;

                const lastHistory = item.appointmentId.appointmentHistory[
                    item.appointmentId.appointmentHistory.length - 1
                ];

                return lastHistory?.summary?.some(
                    prescriptionItem => prescriptionItem._id === item._id
                );
            });
        };

        setHasEditableSummary(checkEditableSummary());
    }, [details]);

    const fetchData = () => {
        axios
            .get(`${apiBaseUrl}/summary/${appointmentId}`)
            .then((response) => {
                if (response.data.success) {
                    setDetails(response.data.data);
                    console.log("Summary Data", response.data.data);
                }
            })
            .catch((error) => {
                console.error("Error fetching summary:", error);
            });
    };

    const showModal = () => {

        setIsModalOpen(true);
    };

    const showUpdateModal = (summary, summaryId) => {
        updateForm.setFieldsValue({
            summary: summary,
        });
        setCurrentSummaryId(summaryId);
        setIsUpdateModalOpen(true);
    };
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                addSummary(values);
                form.resetFields();
                setIsModalOpen(false);
            })
            .catch((info) => {
                console.error("Validation Failed:", info);
            });
    };

    const handleUpdateOk = () => {
        // the value of arg is Summary ID

        updateForm
            .validateFields()
            .then((values) => {
                // console.log("Updated Summary:", { currentSummaryId, ...values });
                updateSummary(values);
                updateForm.resetFields();
                setIsUpdateModalOpen(false);
            })
            .catch((info) => {
                console.error("Validation Failed:", info);
            });
    };

    const handleCancel = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: "Are you sure you want to discard changes?",
                content: "Your changes will not be saved.",
                okText: "Yes, discard",
                cancelText: "No, keep editing",
                onOk: () => {
                    form.resetFields();
                    setSelectedDiseases([]);
                    setIsModalOpen(false);
                },
            });
        } else {
            setIsModalOpen(false);
        }
    };

    const handleUpdateCancel = () => {
        if (updateForm.isFieldsTouched()) {
            Modal.confirm({
                title: "Are you sure you want to discard changes?",
                content: "Your changes will not be saved.",
                okText: "Yes, discard",
                cancelText: "No, keep editing",
                onOk: () => {
                    updateForm.resetFields();
                    setSelectedDiseases([]);
                    setIsUpdateModalOpen(false);
                },
            });
        } else {
            setIsUpdateModalOpen(false);
        }
    };

    const updateSummary = async (values) => {
        setActionLoading(true);
        // console.log("Logging in update summary ", values, id);
        const data = {
            summary: values.summary,
        };
        try {
            let response;
            if (localStorage.getItem("doctorticket")) {
                response = await axios.put(
                    `${apiBaseUrl}/doctor/summary/${currentSummaryId}`,
                    data,
                    config
                );
            } else if (localStorage.getItem("staffticket")) {
                response = await axios.put(
                    `${apiBaseUrl}/staff/summary/${currentSummaryId}`,
                    data,
                    config
                );
            }

            if (response.data.success) {
                message.success(response.data.message, 0.6, function onClose() {
                    // window.location.reload();
                    fetchData();
                });
            } else {
                message.error(
                    response?.data?.error?.error || "Failed to update summary."
                );
            }
        } catch (error) {
            console.error("Failed to update summary:", error);
            message.error("Failed to update summary! Please try again.");
        } finally {
            setActionLoading(false);
        }
    }

    const addSummary = async (values) => {
        setActionLoading(true);

        const data = {
            summary: values.summary,
        };

        try {
            let response;
            if (localStorage.getItem("doctorticket")) {
                response = await axios.post(
                    `${apiBaseUrl}/doctor/summary/${appointmentId}`,
                    data,
                    config
                );
            } else if (localStorage.getItem("staffticket")) {
                response = await axios.post(
                    `${apiBaseUrl}/staff/summary/${appointmentId}`,
                    data,
                    config
                );
            }

            if (response.data.success) {
                message.success(response.data.message, 0.6, function onClose() {
                    // window.location.reload();
                    fetchData();
                });
            } else {
                message.error(
                    response?.data?.error?.error || "Failed to add summary."
                );
            }
        } catch (error) {
            console.error("Failed to add summary:", error);
            message.error("Failed to add summary! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    const deleteSummary = async (summary_id) => {
        try {
            setActionLoading(true);
            if (window.confirm("Are you sure want to delete this summary?")) {
                let response;
                if (localStorage.getItem("doctorticket")) {
                    response = await axios.delete(
                        `${apiBaseUrl}/doctor/summary/${summary_id}`,
                        config
                    );
                } else if (localStorage.getItem("staffticket")) {
                    response = await axios.delete(
                        `${apiBaseUrl}/staff/summary/${summary_id}`,
                        config
                    );
                }
                if (response.data.success) {
                    message.success(response.data.message
                        // , 0.6, function onClose() {
                        // window.location.reload();
                        // }
                    );
                    fetchData();
                } else {
                    message.error(
                        response?.error?.message || "Failed to delete the summary."
                    );
                }
            }
        } catch (e) {
            console.error("Failed to delete summary:", e);
            message.error("Failed to delete! Please try again.");
        } finally {
            setActionLoading(false);
        }
    };

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <>
            {actionLoading && <LoadingScreen />}

            <div className="mb-3">
                <h1 className="text-3xl font-bold decoration-gray-400">Summary</h1>
            </div>

            <div className="flex items-start">
                <div className="max-w-5xl p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
                    {loading && <LoadingScreen />}

                    {!loading && (
                        <>
                            <div className="text-center">
                                <div className="flex justify-end">
                                    <button className="px-4 py-1 mb-2 rounded-md bg-blue-600 text-sky-100 hover:bg-blue-700" onClick={handleGoBack}>Go Back</button>
                                </div>
                                <h5 className=" text-xl font-bold tracking-tight text-gray-900">
                                    Appointment Number: {" "}
                                    <span className="font-semibold italic">{` ${appointmentNumberData}`}</span>
                                </h5>
                                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                                    Patient Name:{" "}
                                    <span className="font-semibold italic">{` ${fullNameData}`}</span>
                                </h5>

                                <p className="mb-1 font-semibold text-gray-700">
                                    Problem: <span className="font-normal">{` ${problemData}`}</span>
                                </p>
                            </div>
                            <hr class="w-48 h-1 mx-auto bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700"></hr>

                            <div>
                                {!hasEditableSummary && (<Tabs
                                    activeKey={activeTabKey}
                                    onChange={handleTabChange}
                                    tabBarExtraContent={{
                                        right: (
                                            <Button type="dashed" onClick={handleAddForm}>
                                                <PlusOutlined /> Add New Form
                                            </Button>
                                        ),
                                    }}
                                >
                                    {forms.map((form) => (
                                        <TabPane
                                            tab={
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ marginRight: 8 }}>{`Form ${form.id}`}</span>
                                                    <CloseOutlined
                                                        style={{ color: "#999" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveForm(form.id);
                                                        }}
                                                    />
                                                </div>
                                            }
                                            key={String(form.id)}
                                        >
                                            {renderForm(form)}
                                        </TabPane>
                                    ))}
                                </Tabs>)}
                                {/* Global Submit All button */}
                                <div style={{ padding: "20px", textAlign: "center" }}>
                                    <Button type="primary" onClick={handleSubmitAll}>
                                        Add All Disease Summary
                                    </Button>
                                </div>

                                {/* Global modal for extra inputs */}
                                <Modal
                                    title={dynamicExtra ? dynamicExtra.extraOption.label : "Extra Input"}
                                    open={dynamicExtra !== null}
                                    onOk={handleDynamicExtraOk}
                                    onCancel={handleDynamicExtraCancel}
                                >
                                    <Form layout="vertical">
                                        {dynamicExtra && dynamicExtra.extraOption.placeholders ? (
                                            dynamicExtra.extraOption.placeholders.map((placeholder) => (
                                                <Form.Item label={placeholder.label} key={placeholder.key}>
                                                    {placeholder.type === "select" ? (
                                                        <Select
                                                            placeholder={`Select ${placeholder.label}`}
                                                            value={dynamicExtra.values[placeholder.key] || undefined}
                                                            onChange={(value) =>
                                                                setDynamicExtra((prev) => ({
                                                                    ...prev,
                                                                    values: {
                                                                        ...prev.values,
                                                                        [placeholder.key]: value,
                                                                    },
                                                                }))
                                                            }
                                                        >
                                                            {placeholder.options.map((opt) => (
                                                                <Select.Option key={opt} value={opt}>
                                                                    {opt}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    ) : placeholder.type === "multiselect" ? (
                                                        <Select
                                                            mode="multiple"
                                                            placeholder={`Select ${placeholder.label}`}
                                                            value={dynamicExtra.values[placeholder.key] || []}
                                                            onChange={(value) =>
                                                                setDynamicExtra((prev) => ({
                                                                    ...prev,
                                                                    values: {
                                                                        ...prev.values,
                                                                        [placeholder.key]: value,
                                                                    },
                                                                }))
                                                            }
                                                        >
                                                            {placeholder.options.map((opt) => (
                                                                <Select.Option key={opt} value={opt}>
                                                                    {opt}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    )
                                                        : placeholder.type === "checkbox" ? (
                                                            <Checkbox.Group
                                                                options={placeholder.options}
                                                                value={dynamicExtra.values[placeholder.key] || []}
                                                                onChange={(checkedValues) =>
                                                                    setDynamicExtra((prev) => ({
                                                                        ...prev,
                                                                        values: {
                                                                            ...prev.values,
                                                                            [placeholder.key]: checkedValues,
                                                                        },
                                                                    }))
                                                                }
                                                            />
                                                        ) : (
                                                            <Input
                                                                placeholder={`Enter ${placeholder.label}`}
                                                                value={dynamicExtra.values[placeholder.key] || ""}
                                                                onChange={(e) =>
                                                                    setDynamicExtra((prev) => ({
                                                                        ...prev,
                                                                        values: {
                                                                            ...prev.values,
                                                                            [placeholder.key]: e.target.value,
                                                                        },
                                                                    }))
                                                                }
                                                            />
                                                        )}
                                                </Form.Item>
                                            ))
                                        ) : (
                                            <Form.Item label="Input">
                                                <Input
                                                    placeholder="Enter value"
                                                    value={dynamicExtra ? dynamicExtra.value : ""}
                                                    onChange={(e) =>
                                                        setDynamicExtra((prev) => ({ ...prev, value: e.target.value }))
                                                    }
                                                />
                                            </Form.Item>
                                        )}
                                    </Form>
                                </Modal>

                                {/* Global debug for all forms data */}
                                <div
                                    style={{
                                        background: "#f0f0f0",
                                        padding: "10px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Title level={4}>Combined Forms Data</Title>
                                    <pre>{JSON.stringify(forms, null, 2)}</pre>
                                </div>
                            </div>

                            {details.length > 0 ? (
                                details.map((item) => {
                                    return (
                                        <div
                                            key={item._id}
                                            className="relative mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex"
                                        >
                                            {/* Left Content */}
                                            <div className="flex-grow">
                                                <p
                                                    className="mb-1 font-semibold text-gray-700"
                                                    style={{ whiteSpace: "pre-wrap" }}
                                                >
                                                    Summary Report:{" "}
                                                    <span className="font-normal">{item.summary}</span>
                                                </p>
                                                <p className="mb-1 font-semibold text-gray-700">
                                                    Added by:{" "}
                                                    <span className="font-normal">
                                                        Dr. {item.doctorId.fullname}
                                                    </span>
                                                </p>
                                            </div>

                                            {/* Dotted Vertical Line */}
                                            <div className="relative">
                                                <div className="absolute top-0 bottom-0 border-r-2 border-dotted border-gray-400"></div>
                                            </div>

                                            {/* Buttons Section */}

                                            {item.appointmentId.appointmentHistory[item.appointmentId.appointmentHistory.length - 1]?.summary.some(summaryItem =>
                                                summaryItem._id === item._id
                                            ) && (<div className="flex flex-col items-start pl-6">
                                                {/* <Link */}
                                                {/* // to={`/dashboard/viewOngoingAppointmentbyDoctor/update_prescription/${item._id}`}
                        // state={{ appointmentId: appointmentId }} */}
                                                {/* > */}
                                                <div className="ms-2">
                                                    <button className="px-4 py-1 mb-2 rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700" onClick={() => showUpdateModal(item.summary, item._id)}>
                                                        Update
                                                    </button>
                                                    <Modal
                                                        title="Update Summary"
                                                        open={isUpdateModalOpen}
                                                        onOk={handleUpdateOk}
                                                        onCancel={handleUpdateCancel}
                                                    >
                                                        <Form form={updateForm} onFinish={updateSummary} layout="vertical">
                                                            <Row gutter={[16, 16]}>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        label="Test Type"
                                                                        name="testType"
                                                                    >
                                                                        <Select
                                                                            placeholder="Select test type"
                                                                            onChange={handleDiseaseSelectUpdate}
                                                                            className="w-full"
                                                                        >
                                                                            {/* {Object.keys(diseaseOptions).map(category => (
                                        <Select.Option key={category} value={category}>
                                          {category}
                                        </Select.Option>
                                      ))} */}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <div className="mb-4 flex flex-wrap gap-2">
                                                                        {selectedDiseases.map(category => (
                                                                            <Tag
                                                                                key={category}
                                                                                closable
                                                                                onClose={() => handleDiseaseRemoveUpdate(category)}
                                                                                className="px-2 py-1"
                                                                            >
                                                                                {category}
                                                                            </Tag>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        label="Summary"
                                                                        name="summary"
                                                                        rules={[
                                                                            { required: true, message: "Please enter summary." },
                                                                        ]}
                                                                    >
                                                                        <TextArea rows={13} placeholder="Enter summary..." />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Modal>
                                                </div>
                                                {/* </Link> */}
                                                <Link
                                                    onClick={() => {
                                                        deleteSummary(item._id);
                                                    }}
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                >
                                                    <div className="ms-2">
                                                        <button className="px-5 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800">
                                                            Delete
                                                        </button>
                                                    </div>
                                                </Link>
                                            </div>)}
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    <p className="mt-3 text-center">
                                        <h5 className="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                                            No Summary added!!
                                        </h5>
                                    </p>
                                </>
                            )}
                        </>
                    )}
                </div>

            </div>
        </>
    );
};
export default ViewSummary;
