export const MYOSITIS = [
    /// Initial Symptom
    {
        field: "ms-InitialSymptom",
        label: "Initial Symptom",
        type: "multiselect",
        options: [
            "Proximal muscle weakness",
            "Whole body aches",
            "Joint pain and swelling",
            "Constitutional",
            "Shortness of breath",
            "Swelling of extremities",
            "Mechanic's hands",
            "Raynaud's phenomenon",
            "Gottron's papules",
            "Heliotrope rash",
            "Shawl rash",
            "V rash",
        ]
    },
    /// Initial muscle power
    {
        field: "ms-InitialMusclePower",
        label: "Initial muscle power",
        type: "type",
    },
    /// Initial Investigation
    {
        field: "ms-InitialInvestigation",
        label: "Initial Investigation",
        type: "multiselect",
        options: [
            "CK Total {____}",
            "CRP {____}",
            "SGOT {____}",
        ],
        extraOptions: [

            {
                // When the user selects this trigger value…
                trigger: [
                    "CK Total {____}",
                    "CRP {____}",
                    "SGOT {____}",
                ],
                placeholders: [

                    {
                        key: "____",
                        type: "type",
                        label: "Value",
                    }
                ]
            },
        ]
    },
    /// Muscle biopsy   
    {
        field: "ms-MuscleBiopsy",
        label: "Muscle biopsy",
        type: "type",
    },
    /// MRI
    {
        field: "ms-MRI",
        label: "MRI",
        type: "type",
    },
    /// Myositis-Specific Antibodies (MSAs) 
    {
        field: "ms-Myositis-SpecificAntibodies(MSAs)",
        label: "Myositis-Specific Antibodies (MSAs)",
        type: "multiselect",
        options: [
            "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
            "Anti-Mi-2 {+1/+2/+3}",
            "Anti-TIF1-γ (Anti-p155/140) {+1/+2/+3}",
            "Anti-NXP-2 {+1/+2/+3}",
            "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
            "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
            "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-Jo-1 (Anti-histidyl-tRNA synthetase) {+1/+2/+3}",
                    "Anti-Mi-2 {+1/+2/+3}",
                    "Anti-TIF1-γ (Anti-p155/140) {+1/+2/+3}",
                    "Anti-NXP-2 {+1/+2/+3}",
                    "Anti-MDA5 (Anti-CADM-140) {+1/+2/+3}",
                    "Anti-SRP (Signal Recognition Particle) {+1/+2/+3}",
                    "Anti-HMGCR (3-Hydroxy-3-Methylglutaryl-CoA Reductase) {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select",
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
            },
        ]
    },
    /// Myositis-Associated Antibodies (MAAs)
    {
        field: "ms-Myositis-AssociatedAntibodies(MAAs)",
        label: "Myositis-Associated Antibodies (MAAs)",
        type: "multiselect",
        options: [
            "Anti-U1 RNP {+1/+2/+3}",
            "Anti-PM/Scl {+1/+2/+3}",
            "Anti-Ku {+1/+2/+3}",
            "Anti-Ro/SSA (Anti-SSA/Ro52) {+1/+2/+3}",
        ],
        extraOptions: [
            {
                // When the user selects this trigger value…
                trigger: [
                    "Anti-U1 RNP {+1/+2/+3}",
                    "Anti-PM/Scl {+1/+2/+3}",
                    "Anti-Ku {+1/+2/+3}",
                    "Anti-Ro/SSA (Anti-SSA/Ro52) {+1/+2/+3}",
                ],
                placeholders: [
                    {
                        key: "+1/+2/+3",
                        type: "select",
                        label: "Strength",
                        options: [
                            "+1",
                            "+2",
                            "+3",
                        ],
                    }
                ]
            },
        ]
    },
    /// Initially Treated with
    {
        field: "ms-initiallyTreatedWith",
        label: "Initially treated with",
        type: "multiselect",
        options: [
            "Pulse methylprednisolone {value1} mg for {duration} Days",
            "Cyclophosphamide {value1} mg for {duration} months",
            "Rituximab {value}",
            "Methotrexate {value} mg per week",
            "Mycophenolate {value} mg twice a day",
            "Azathioprine {value} mg once a day",
            "Prednisolone {value} mg per day",
            "Hydroxychloroquine {value} mg",
            "Tadalafil 10mg",
            "Nifedipine 20mg SR",
            "Nintadinib 150mg BD",
        ],
        extraOptions: [

            {
                // When the user selects this trigger value…
                trigger: [
                    "Pulse methylprednisolone {value1} mg for {duration} Days",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "select",
                        label: "Dose",

                        options: [
                            "100",
                            "250",
                            "500",
                            "750",
                            "1000",
                        ]
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Days",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                        ]
                    }
                ]
            },
            {
                // When the user selects this trigger value…
                trigger: [
                    "Cyclophosphamide {value1} mg for {duration} months",
                ],
                placeholders: [

                    {
                        key: "value1",
                        type: "type",
                        label: "Dose",
                    },
                    {
                        key: "duration",
                        type: "select",
                        label: "Months",

                        options: [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Rituximab {value}",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500 mg",
                            "1 g",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Methotrexate {value} mg per week",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "7.5",
                            "10",
                            "12.5",
                            "15",
                            "17.5",
                            "20",
                            "25",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Mycophenolate {value} mg twice a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "500",
                            "1000",
                            "1500",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Azathioprine {value} mg once a day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "25",
                            "50",
                            "75",
                            "100",
                            "125",
                            "150",
                        ]
                    }
                ]
            },
            {
                trigger: [
                    "Prednisolone {value} mg per day",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: Array.from({ length: 32 }, (_, i) => 2.5 * (i + 1)),
                    }
                ]
            },
            {
                trigger: [
                    "Hydroxychloroquine {value} mg",
                ],
                placeholders: [
                    {
                        key: "value",
                        type: "select",
                        label: "Dose",

                        options: [
                            "200",
                            "300",
                            "400",
                        ],
                    }
                ]
            },
        ]
    },
    /// Response Field
    {
        field: "ms-response",
        label: "Response",
        type: "select",
        options: [
            "Improved on initial therapy",
            "Did not improve on initial therapy",
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "ms-aNA-By-IFA-Intensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "ms-aNA-By-IFA-Titer",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "ms-aNA-By-IFA-Pattern",
        label: "ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Comorbidities Field
    {
        field: "ms-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "ms-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "ms-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "ms-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "ms-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "ms-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Progressive weakness",
            "Worsening skin rash",
            "New skin rashes",
            "Fever",
            "Cough",
        ]
    }
];