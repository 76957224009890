export const OVERLAP_SYNDROMES = [
    /// Overlapped Diseases
    {
        field: "vas-overlappedDiseases",
        label: "Overlapped Diseases",
        type: "composite",
        fields: [
            {
                field: "overlapDisease1",
                label: "OverlapDisease Number 1",
                type: "select",
                options: [
                    "RHEUMATOID_ARTHRITIS",
                    "SYSTEMIC_LUPUS_ERYTHEMATOSUS",
                    "SPONDYLOARTHRITIS",
                    "SYSTEMIC_SCLEROSIS",
                    "MYOSITIS",
                    "MIXED_CONNECTIVE_TISSUE",
                    "GOUTY_ARTHRITIS",
                    "VASCULITIS",
                    "DEGENERATIVE_DISEASE",
                    "PRIMARY_SJÖGREN_DISEASE",
                    "UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE",
                ]
            },
            {
                field: "overlapDisease2",
                label: "OverlapDisease Number 2",
                type: "select",
                options: [
                    "RHEUMATOID_ARTHRITIS",
                    "SYSTEMIC_LUPUS_ERYTHEMATOSUS",
                    "SPONDYLOARTHRITIS",
                    "SYSTEMIC_SCLEROSIS",
                    "MYOSITIS",
                    "MIXED_CONNECTIVE_TISSUE",
                    "GOUTY_ARTHRITIS",
                    "VASCULITIS",
                    "DEGENERATIVE_DISEASE",
                    "PRIMARY_SJÖGREN_DISEASE",
                    "UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE",
                ]
            },
            {
                field: "predominantFeatureOf",
                label: "Pre-Dominant Feature Of",
                type: "select",
                options: [
                    "RHEUMATOID_ARTHRITIS",
                    "SYSTEMIC_LUPUS_ERYTHEMATOSUS",
                    "SPONDYLOARTHRITIS",
                    "SYSTEMIC_SCLEROSIS",
                    "MYOSITIS",
                    "MIXED_CONNECTIVE_TISSUE",
                    "GOUTY_ARTHRITIS",
                    "VASCULITIS",
                    "DEGENERATIVE_DISEASE",
                    "PRIMARY_SJÖGREN_DISEASE",
                    "UNDIFFERENTIATED_CONNECTIVE_TISSUE_DISEASE",
                ]
            },
        ]
    },
    /// Initial Symptom
    {
        field: "os-initialSymptoms",
        label: "Initial Symptoms",
        type: "multiselect",
        options: [
            "Arthritis",
            "Proximal muscle weakness",
            "Photosensitivity",
            "Constitutional",
            "Raynaud's",
            "Renal involvement",
            "Cardiac involvement",
            "Lung involvement",
        ],
    },
    /// Pertinent Antibodies
    {
        field: "os-pertinentAntibodies",
        label: "Pertinent Antibodies",
        type: "multiselect",
        options: [
            "Anti-U1 RNP Antibody",
            "Anti-Jo-1",
            "Anti-SRP, Anti-Mi-2",
            "Anti-MDA5",
            "Anti-Scl-70 (Topoisomerase I)",
            "Anti-Centromere",
            "Anti-RNA Polymerase III",
            "Anti-dsDNA",
            "Anti-Sm",
            "Anti-Ro/SSA and Anti-La/SSB",
            "Rheumatoid Factor (RF)",
            "Anti-Cyclic Citrullinated Peptide (Anti-CCP)",
            "Anti-cardiolipin",
            "Beta-2 glycoprotein I",
            "Lupus anticoagulant",
            "Anti-Ku",
            "Anti-PM/Scl",
            "Anti-RNP and Anti-Sm",
        ],
    },
    /// ANA by-IFA : Intensity Field
    {
        field: "os-aNA-By-IFA-Intensity",
        label: "ANA by-IFA : Intensity",
        type: "select",
        options: [
            "+1",
            "+2",
            "+3",
        ],

    },
    /// ANA by-IFA : Titer Field
    {
        field: "os-aNA-By-IFA-Titer",
        label: "ANA by-IFA : Titer",
        type: "select",
        options: [
            "1:80",
            "1:160",
            "1:320",
            "1:640",
            "1:1280",
            "1:2560",
            "1:5120",
        ],

    },
    /// ANA by-IFA : Pattern Field
    {
        field: "os-aNA-By-IFA-Pattern",
        label: "ANA by-IFA : Pattern",
        type: "select",
        options: [
            "Homogeneous (Diffuse) Pattern",
            "Speckled Pattern",
            "Nucleolar Pattern",
            "Centromere Pattern",
            "Cytoplasmic Pattern",
            "Peripheral Patttern",
            "Mitotic Pattern",
            "Dense Fine Speckled Pattern",
        ],

    },
    /// Comorbidities Field
    {
        field: "os-comorbidities",
        label: "Comorbidities",
        type: "multiselect",
        options: [
            "Hypertension", "Cardio Vascular Disease", "COPD", "Hyperthyroidism",
            "Diabetes",
            "Dislipidemia",
            "Hypothyroidism",
        ],
    },
    /// Conception Plan Field
    {
        field: "os-conceptionPlan",
        label: "Conception Plan",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Compliant to medication and advices Field
    {
        field: "os-compliantToMedicationAndAdvices",
        label: "Compliant to medication and advices",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Vaccination Status Field
    {
        field: "os-vaccinationStatus",
        label: "Vaccination Status",
        type: "select",
        options: [
            "Received pneumoccal vaccine",
            "Did not receive pneumoccal vaccine",
            "Refused pneumoccal vaccine",
        ],
    },
    /// Smoker Field
    {
        field: "os-smoker",
        label: "Smoker",
        type: "checkbox",
        options: ["Yes", "No"],
    },
    /// Current Issues
    {
        field: "os-currentIssues",
        label: "Current Issues",
        type: "multiselect",
        options: [
            "Symptoms suggestive of increased disease activity",
            "Symptoms suggestive of side effects of drugs",
            "Symptoms suggestive of infection",
        ]
    }
]